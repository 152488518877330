'use strict'

###*
 # @ngdoc object
 # @name mundoMap.controller:MundoMapCtrl

 # @description

###
class MundoMapCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'MundoMapCtrl'

angular
  .module('mundoMap')
  .controller 'MundoMapCtrl', MundoMapCtrl
